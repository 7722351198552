/*** The new CSS Reset - version 1.2.0 (last updated 23.7.2021) ***/
/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  Remove list styles (bullets/numbers)
  in case you use it with normalize.css
*/
ol, ul {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* Removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

html, body {
  font-family: 'Magra', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

a {
  color: #c30000;
}
a:hover,
a:focus {
  text-decoration: underline;
  cursor: pointer;
}
strong {
  font-weight: bold;
}
h1 {
  font-size: 7rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.7rem;
}
i {
  font-style: italic;
}

.content {
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
}

header {
  background-image: url(pixel_weave_opt.png);
  background-attachment: fixed;
  border-bottom: 1px solid #C4C4C4;
  color: #FFF;
  box-shadow: inset 0 -6px 10px -10px rgba(0,0,0,0.4);
  padding-top: 0.1px;
}
header .content {
  display: flex;
  align-items: flex-end;
  margin: 5rem auto 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
header h1 {
  line-height: 7rem;
  text-shadow: 0 1px 3px #000;
  font-weight: normal;
  vertical-align: baseline;
  flex-grow: 1;
}

.social {
  vertical-align: baseline;
  white-space: nowrap;
  margin-top: 2rem;
}
.social .network {
  transform: rotate(-90deg);
  transform-origin: center left;
  display: inline-block;
  vertical-align: bottom;

  display: inline-block;
  margin: 0 0 0 0.5rem;
  background-color: #c30000;
  border-radius: 3px;

  height: 2rem;
  width: 2rem;
  white-space: nowrap;

  cursor: pointer;
  text-decoration: none;
}
.social .network:hover {
  background-color: #d40000;
}
.social .network .icon {
  display: inline-block;
  transform: rotate(90deg);
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
}
.social .network .icon svg {
  display: inline-block;
  fill: #FFF;
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: middle;
}

.social .network .name {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  overflow: hidden;
  text-shadow: -1px 0px 3px #000;
  color: #FFF;
}
.social .network span {
  display: inline-block;
  margin-left: -120px;
  transition: margin-left 500ms;
}
.social .network:hover span {
  margin-left: 7px;
}

.main {
  position: relative;
}
.main .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 3rem;
}
.funny-background {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: -1;
  opacity: 0.5;
}
.main h2 {
  margin: 1.5rem 0 2rem;
  background-color: #FFF;
  display: inline-block;
}
.main section {
  background-image: linear-gradient(to bottom, #c30000, #c30000 2px, transparent 2px, transparent);
  background-position: center 2.4rem ;
  background-repeat: no-repeat;
  background-size: 100%;
}

#c {
  position: absolute;
  left: 0;
}

.bio {
  text-align: justify;
  flex-grow: 1;
  width: 200px;
  padding-right: 3rem;
}
.bio h2 {
  padding-right: 1rem;
}
.bio p {
  margin-bottom: 1rem;
}
.portrait {
  background-image: url(portrait.png);
  background-size: 100%;
  width: 200px;
  height: 200px;
  margin: -10px auto 20px;
}
.tags {
  text-align: left;
}
.tags span  {
  display: inline-block;
  /*padding: 0.3rem 0.5rem;*/
  margin: 0.2rem 0.2rem;
  color: #c30000;
  font-style: italic;
  border-radius: 3px;
}
.tags span:before {
  content: '#';
}

.resume {
  width: 400px;
  flex-grow: 3;
  text-align: right;
}
.resume h2 {
  padding-left: 1rem;
}
.timeline {
  text-align: left;
}
.timeline h3 {
  margin: 2rem 0 1rem;
}
.timeline p {
  margin-top: 0.5rem;
}
.timeline h3:first-child {
  margin-top: 0;
}

.footer {
  background-image: url(pixel_weave_inv.png);
  color: #FFF;
  padding: 0.1px;
  background-attachment: fixed;
  border-top: 1px solid #666;
}
.footer .portfolio {
  text-align: left;
  position: relative;
  padding: 0;
}
.footer .portfolio h2 {
  text-align: center;
  margin: 2rem 0;
}

.copyright {
  margin: 2rem 0;
}

.simple-slider {
  max-width: 614px;
  width: 100%;
  height: 364px;
  margin: 0 auto;
  border: 7px solid #FFF;
  white-space: nowrap;
  box-shadow: 0 0 20px rgba(0,0,0,0.8);
  overflow: hidden;
}
.simple-slider-slide {
  position: relative;
  display: inline-block;
  max-width: 600px;
  width: 100%;
  height: 350px;
  vertical-align: top;
  margin-right: -3px;
  transition: margin-left 500ms;
  transition: margin-left 500ms;
  box-shadow: inset 0 0 20px rgba(0,0,0,0.4);
}
.simple-slider-right,
.simple-slider-left {
  line-height: 470px;
  vertical-align: middle;
  display: block;
  position: absolute;
  font-size: 4em;
  top: 0px;
  cursor: pointer;
  text-shadow: 0 0 10px rgba(0,0,0,0.8);
  z-index: 1;
}
.simple-slider-right:hover,
.simple-slider-left:hover {
  text-shadow: 0 0 10px rgba(255,255,255,0.5);
}
.simple-slider-left {
  left: 20px;
  content: '<';
}
.simple-slider-right {
  right: 20px;
  content: '>';
}
.simple-slider-slide > div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0,0,0,0.8);
  padding: 10px;
  white-space: normal;
}
.simple-slider-slide a {
  font-size: 1.2em;
}
.slide-gq,
.slide-jqge,
.slide-ogs,
.slide-rjtc,
.slide-tcx,
.slide-v,
.slide-ic {
  background-position: center;
}

.slide-gq {
  background-image: url(gamequery.png);
}
.slide-jqge {
  background-image: url(book.jpg);
}
.slide-ogs {
  background-image: url(talk.jpg);
}
.slide-rjtc {
  background-image: url(racer.png);
}
.slide-v {
  background-image: url(voxel.png);
}
.slide-fa {
  background-image: url(fate.png);
}

@media (max-width: 655px) {
  .resume,
  .bio {
    text-align: center;
  }
  .resume h2,
  .bio h2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}